var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container mx-auto grid grid-cols-12 gap-6 mt-6" },
      [
        _vm.isEdit
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.company_name")))]
              ),
              _c(
                "div",
                { staticClass: "col-span-10" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: { required: true, max: 255 },
                      fieldId: "company_name",
                    },
                    model: {
                      value: _vm.value.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "company_name", $$v)
                      },
                      expression: "value.company_name",
                    },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm.isEdit
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.company_website")))]
              ),
              _c(
                "div",
                { staticClass: "col-span-10" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: { required: true, max: 255 },
                      fieldId: "company_url",
                    },
                    model: {
                      value: _vm.value.company_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "company_url", $$v)
                      },
                      expression: "value.company_url",
                    },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm.fieldType === "edit"
          ? [
              _vm.isEdit
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-start-1 col-span-2 py-2 text-right font-bold",
                      },
                      [
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(_vm._s(_vm.$t("commons.fields.address"))),
                        ]),
                      ]
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.street")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: { rules: { max: 255 }, fieldId: "hq_street" },
                          model: {
                            value: _vm.value.hq_street,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "hq_street", $$v)
                            },
                            expression: "value.hq_street",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.city")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: { rules: { max: 255 }, fieldId: "hq_city" },
                          model: {
                            value: _vm.value.hq_city,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "hq_city", $$v)
                            },
                            expression: "value.hq_city",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 pt-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("commons.fields.state_province_region"))
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: { rules: { max: 255 }, fieldId: "hq_state" },
                          model: {
                            value: _vm.value.hq_state,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "hq_state", $$v)
                            },
                            expression: "value.hq_state",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.zip_postal_code")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: { rules: { max: 255 }, fieldId: "hq_zip" },
                          model: {
                            value: _vm.value.hq_zip,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "hq_zip", $$v)
                            },
                            expression: "value.hq_zip",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.country")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-select", {
                          staticClass: "col-start-1 col-span-4",
                          attrs: {
                            required: false,
                            options: _vm.allCountries,
                            fieldId: "hq_country",
                            needsTranslation: false,
                          },
                          model: {
                            value: _vm.value.hq_country,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "hq_country", $$v)
                            },
                            expression: "value.hq_country",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("commons.fields.address")) + " "
                        ),
                      ]
                    ),
                    _vm.value.hq_street &&
                    _vm.value.hq_city &&
                    _vm.value.hq_state &&
                    _vm.value.hq_zip
                      ? _c("span", { staticClass: "col-span-10 py-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.value.hq_street +
                                  ", " +
                                  _vm.value.hq_city +
                                  ", " +
                                  _vm.value.hq_state +
                                  " " +
                                  _vm.value.hq_zip
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("commons.fields.company_country")) +
                            " "
                        ),
                      ]
                    ),
                    _vm.value.hq_country
                      ? _c("span", { staticClass: "col-span-10 py-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.textOfEnum(
                                  _vm.allCountries,
                                  _vm.baseConsumer.hq_country
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
            ]
          : _vm._e(),
        _c("label", { staticClass: "col-start-1 col-span-2 pt-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.preferred_language"))),
        ]),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "col-span-10" },
              [
                _c("fl-select", {
                  attrs: {
                    required: true,
                    options: _vm.language,
                    fieldId: "preferred_language",
                  },
                  model: {
                    value: _vm.value.preferred_language,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "preferred_language", $$v)
                    },
                    expression: "value.preferred_language",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "col-span-10 h-10 py-2" },
              [
                _c("fl-show", {
                  attrs: {
                    value: _vm.baseConsumer.preferred_language,
                    options: _vm.language,
                  },
                }),
              ],
              1
            ),
        _c("label", { staticClass: "col-start-1 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.company_type"))),
        ]),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "col-span-10" },
              [
                _c("fl-select", {
                  attrs: {
                    required: true,
                    options: _vm.companyTypeConsumer,
                    fieldId: "company_type",
                  },
                  model: {
                    value: _vm.value.company_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "company_type", $$v)
                    },
                    expression: "value.company_type",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "col-span-10 h-10 py-2" },
              [
                _c("fl-show", {
                  attrs: {
                    value: _vm.baseConsumer.company_type,
                    options: _vm.companyTypeConsumer,
                  },
                }),
              ],
              1
            ),
        _vm.fieldType === "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.business_type")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    _vm._l(_vm.allBusinessTypes, function (right, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "block" },
                        [
                          _c(
                            "FlCheckbox",
                            {
                              attrs: {
                                trueValue: _vm.businessTypeTrueValue(index),
                              },
                              model: {
                                value: _vm.value.business_types,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "business_types", $$v)
                                },
                                expression: "value.business_types",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t(right.text)))])]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "col-span-10 py-2" },
                    _vm._l(_vm.value.business_types, function (item, index) {
                      return _c("fl-show", {
                        key: index,
                        attrs: {
                          value: item.business_type,
                          options: _vm.businessTypes,
                        },
                      })
                    }),
                    1
                  ),
            ]
          : _vm._e(),
        _vm.fieldType === "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.timezone")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    [
                      _c("fl-select", {
                        attrs: {
                          options: _vm.timezones,
                          fieldId: "timezone",
                          needsTranslation: false,
                        },
                        model: {
                          value: _vm.value.timezone,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "timezone", $$v)
                          },
                          expression: "value.timezone",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "col-span-10 h-10 py-2" },
                    [
                      _c("fl-show", {
                        attrs: {
                          value: _vm.baseConsumer.timezone,
                          options: _vm.timezones,
                          useI18n: false,
                        },
                      }),
                    ],
                    1
                  ),
            ]
          : _vm._e(),
        _c("label", { staticClass: "col-start-1 col-span-2 py-2 text-right" }),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "col-span-10" },
              [
                _c(
                  "FlCheckbox",
                  {
                    attrs: { itemValue: true },
                    on: {
                      input: function ($event) {
                        return _vm.changeAllAreas()
                      },
                    },
                    model: {
                      value: _vm.selectAllAreas,
                      callback: function ($$v) {
                        _vm.selectAllAreas = $$v
                      },
                      expression: "selectAllAreas",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("pages.consumers.cart.select_all_area")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("label", { staticClass: "col-start-1 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.distribution_area"))),
        ]),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "col-span-10" },
              [
                _c("InputAreaField", {
                  attrs: {
                    disabled: _vm.selectAllAreas,
                    rules: { required: !_vm.selectAllAreas },
                    fieldId: "areas",
                    inputErrors: _vm.otherErrors.areas,
                  },
                  model: {
                    value: _vm.value.areas,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "areas", $$v)
                    },
                    expression: "value.areas",
                  },
                }),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.otherErrors.areas,
                        expression: "otherErrors.areas",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 mt-1",
                  },
                  [_vm._v(_vm._s(_vm.otherErrors.areas))]
                ),
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass:
                  "field col-span-10 py-2 flex justify-start flex-wrap content-around",
              },
              [
                _vm._l(_vm.baseConsumer.areas, function (area, key) {
                  return [
                    _c(
                      "div",
                      {
                        key: key,
                        staticClass:
                          "py-1 px-2 mr-2 genre-tag-label text-sm text-white",
                      },
                      [_vm._v(_vm._s(_vm.$t(area.text)))]
                    ),
                  ]
                }),
              ],
              2
            ),
        _c("FlShowGenre", {
          attrs: {
            fieldId: "mainGenres",
            isEdit: _vm.isEdit,
            isMainGenre: true,
            areaClass: "col-span-12",
            areaGrid: 12,
            labelGrid: 2,
            labelClass: "py-2",
            inputErrors: _vm.otherErrors,
            rules: { required: true },
          },
          model: {
            value: _vm.value.genres,
            callback: function ($$v) {
              _vm.$set(_vm.value, "genres", $$v)
            },
            expression: "value.genres",
          },
        }),
        _vm.fieldType === "edit"
          ? [
              _c("FlShowGenre", {
                attrs: {
                  fieldId: "subGenres",
                  isEdit: _vm.isEdit,
                  isMainGenre: false,
                  areaClass: "col-span-12",
                  areaGrid: 12,
                  labelGrid: 2,
                  labelClass: "py-2",
                  inputErrors: _vm.otherErrors,
                },
                model: {
                  value: _vm.value.genres,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "genres", $$v)
                  },
                  expression: "value.genres",
                },
              }),
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.annual_revenue")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "inline-block col-span-2" },
                    [
                      _c("fl-select", {
                        attrs: {
                          required: false,
                          options: _vm.currency,
                          fieldId: "annual_revenue_currency",
                          needsTranslation: false,
                        },
                        model: {
                          value: _vm.value.annual_revenue_currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "annual_revenue_currency", $$v)
                          },
                          expression: "value.annual_revenue_currency",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-8" },
                    [
                      _c("fl-inline-input", {
                        attrs: {
                          fieldId: "annual_revenue_size",
                          disabled: false,
                          rules: {
                            integer: true,
                          },
                          type: "number",
                        },
                        model: {
                          value: _vm.value.annual_revenue_size,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "annual_revenue_size",
                              _vm._n($$v)
                            )
                          },
                          expression: "value.annual_revenue_size",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.value.annual_revenue_size &&
                            _vm.value.annual_revenue_currency
                            ? _vm.formatCurrency(
                                _vm.value.annual_revenue_size,
                                _vm.value.annual_revenue_currency
                              )
                            : ""
                        ) +
                        " "
                    ),
                  ]),
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.number_of_users")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-8" },
                    [
                      _c("fl-inline-input", {
                        attrs: {
                          fieldId: "user_count",
                          disabled: false,
                          rules: {
                            integer: true,
                          },
                          type: "number",
                        },
                        model: {
                          value: _vm.value.user_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "user_count", _vm._n($$v))
                          },
                          expression: "value.user_count",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.baseConsumer.user_count
                            ? _vm.baseConsumer.user_count.toLocaleString(
                                "en-US"
                              )
                            : 0
                        ) +
                        " "
                    ),
                  ]),
            ]
          : _vm._e(),
        _vm.fieldType === "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.phone_number")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    [
                      _c("VuePhoneNumberInput", {
                        staticStyle: { "font-family": "sans-serif" },
                        attrs: {
                          inputErrors: _vm.otherErrors,
                          "default-country-code": _vm.defaultCountryCode,
                          translations: _vm.phoneFieldTranslations,
                        },
                        on: { update: _vm.getPhoneNumber },
                        model: {
                          value: _vm.value.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "tel", $$v)
                          },
                          expression: "value.tel",
                        },
                      }),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.otherErrors.tel,
                              expression: "otherErrors.tel",
                            },
                          ],
                          staticClass: "text-xxs text-left text-red-600 mt-1",
                        },
                        [_vm._v(_vm._s(_vm.otherErrors.tel))]
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("phoneNumber")(_vm.baseConsumer.tel)) +
                        " "
                    ),
                  ]),
            ]
          : _vm._e(),
        _c("label", { staticClass: "col-start-1 col-span-2 text-right py-2" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.name"))),
        ]),
        _vm.$i18n.locale === "ja"
          ? [
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10 grid grid-cols-4 gap-x-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-span-2" },
                        [
                          _c("fl-labeled-input", {
                            attrs: {
                              rules: { required: true, max: 255 },
                              sublabel: _vm.$t("commons.fields.last_name"),
                              fieldId: "last_name",
                            },
                            model: {
                              value: _vm.value.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "last_name", $$v)
                              },
                              expression: "value.last_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-span-2" },
                        [
                          _c("fl-labeled-input", {
                            attrs: {
                              rules: { required: true, max: 255 },
                              sublabel: _vm.$t("commons.fields.first_name"),
                              fieldId: "first_name",
                            },
                            model: {
                              value: _vm.value.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "first_name", $$v)
                              },
                              expression: "value.first_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "field col-span-10 flex justify-start flex-wrap content-around",
                    },
                    [
                      _c("p", { staticClass: "mr-2 py-2 h-10" }, [
                        _vm._v(_vm._s(_vm.baseConsumer.last_name)),
                      ]),
                      _c("p", { staticClass: "py-2 h-10" }, [
                        _vm._v(_vm._s(_vm.baseConsumer.first_name)),
                      ]),
                    ]
                  ),
            ]
          : [
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10 grid grid-cols-4 gap-x-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-span-2" },
                        [
                          _c("fl-labeled-input", {
                            attrs: {
                              rules: { required: true, max: 255 },
                              sublabel: _vm.$t("commons.fields.first_name"),
                              fieldId: "first_name",
                            },
                            model: {
                              value: _vm.value.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "first_name", $$v)
                              },
                              expression: "value.first_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-span-2" },
                        [
                          _c("fl-labeled-input", {
                            attrs: {
                              rules: { required: true, max: 255 },
                              sublabel: _vm.$t("commons.fields.last_name"),
                              fieldId: "last_name",
                            },
                            model: {
                              value: _vm.value.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "last_name", $$v)
                              },
                              expression: "value.last_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "field col-span-10 flex justify-start flex-wrap content-around",
                    },
                    [
                      _c("p", { staticClass: "mr-2 py-2 h-10" }, [
                        _vm._v(_vm._s(_vm.baseConsumer.last_name)),
                      ]),
                      _c("p", { staticClass: "py-2 h-10" }, [
                        _vm._v(_vm._s(_vm.baseConsumer.first_name)),
                      ]),
                    ]
                  ),
            ],
        _c("label", { staticClass: "col-start-1 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.mail_address"))),
        ]),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "col-span-10" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    rules: { required: true, max: 255 },
                    fieldId: "email",
                  },
                  model: {
                    value: _vm.value.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "email", $$v)
                    },
                    expression: "value.email",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "col-span-10 py-2" },
              [_c("fl-show", { attrs: { value: _vm.baseConsumer.email } })],
              1
            ),
        _vm.fieldType !== "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.password")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    [
                      _c("fl-labeled-input", {
                        attrs: {
                          type: "password",
                          rules: { required: true, min: 8 },
                          fieldId: "password",
                        },
                        model: {
                          value: _vm.value.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "password", $$v)
                          },
                          expression: "value.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm.fieldType == "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.channels")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    [
                      _vm._l(_vm.value.channels, function (channel, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            staticClass:
                              "w-full h-8 mb-3 text-left channel_field flex",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "w-2/12 mr-2 py-2 px-2 h-10",
                                attrs: { placeholder: "Channel Name" },
                              },
                              [_vm._v(_vm._s(channel.name))]
                            ),
                            _c("FlTruncatableSpan", {
                              staticClass: "w-4/5 py-2 px-2 h-10",
                              attrs: { value: channel.url },
                            }),
                            _c(
                              "button",
                              {
                                ref: "channelDelete",
                                refInFor: true,
                                staticClass:
                                  "w-2/12 px-3 pay-1 bg-base-yellow font-bold float-right h-10",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteChannel(channel)
                                  },
                                },
                              },
                              [_vm._v(" DELETE ")]
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full h-8 text-left channel_field relative",
                        },
                        [
                          _c("input", {
                            ref: "channelName",
                            staticClass: "w-2/12 mr-2 input_text",
                            attrs: {
                              type: "text",
                              placeholder: "Channel Name",
                            },
                          }),
                          _c("input", {
                            ref: "channelUrl",
                            staticClass: "w-3/5 mr-2 input_text",
                            attrs: { type: "text", placeholder: "Channel URL" },
                          }),
                          _c(
                            "button",
                            {
                              ref: "channelAdd",
                              staticClass:
                                "w-2/12 px-3 py-1 bg-base-yellow font-bold float-right h-10",
                              on: { click: _vm.addChannel },
                            },
                            [_vm._v(" ADD ")]
                          ),
                        ]
                      ),
                    ],
                    2
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "field col-span-10 flex justify-start flex-wrap content-around",
                    },
                    _vm._l(_vm.value.channels, function (channel, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "w-full h-8 mb-3 text-left channel_field flex",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mr-2 py-2 h-10",
                              attrs: { placeholder: "Channel Name" },
                            },
                            [_vm._v(_vm._s(channel.name))]
                          ),
                          _c("FlTruncatableSpan", {
                            staticClass: "py-2 h-10",
                            attrs: { value: channel.url },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
            ]
          : _vm._e(),
        _vm.fieldType == "edit"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.freeword")))]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "col-span-10" },
                    [
                      _c("fl-labeled-input", {
                        attrs: { rules: { max: 255 }, fieldId: "free_word" },
                        model: {
                          value: _vm.value.free_word,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "free_word", $$v)
                          },
                          expression: "value.free_word",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "col-span-5 py-2" },
                    [
                      _c("fl-show", {
                        attrs: { value: _vm.baseConsumer.free_word },
                      }),
                    ],
                    1
                  ),
            ]
          : _vm._e(),
        _vm.fieldType == "edit"
          ? [
              _vm.isEdit
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-start-1 col-span-2 py-2 text-right font-bold",
                      },
                      [
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("commons.fields.delivery_address"))
                          ),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticClass: "profile-input-field col-span-5",
                    }),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.country")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-select", {
                          staticClass: "col-start-1 col-span-4",
                          attrs: {
                            required: false,
                            options: _vm.allCountries,
                            fieldId: "address_country",
                            needsTranslation: false,
                          },
                          model: {
                            value: _vm.value.address_country,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_country", $$v)
                            },
                            expression: "value.address_country",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.full_name")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: { rules: { max: 255 }, fieldId: "full_name" },
                          model: {
                            value: _vm.value.address_fullname,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_fullname", $$v)
                            },
                            expression: "value.address_fullname",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.street")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { max: 255 },
                            fieldId: "address_street",
                          },
                          model: {
                            value: _vm.value.address_street,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_street", $$v)
                            },
                            expression: "value.address_street",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.city")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { max: 255 },
                            fieldId: "address_city",
                          },
                          model: {
                            value: _vm.value.address_city,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_city", $$v)
                            },
                            expression: "value.address_city",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 pt-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("commons.fields.state_province_region"))
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { max: 255 },
                            fieldId: "address_state",
                          },
                          model: {
                            value: _vm.value.address_state,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_state", $$v)
                            },
                            expression: "value.address_state",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.zip_postal_code")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-10" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            rules: { max: 255 },
                            fieldId: "address_zip",
                          },
                          model: {
                            value: _vm.value.address_zip,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "address_zip", $$v)
                            },
                            expression: "value.address_zip",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("commons.fields.delivery_address"))
                        ),
                      ]
                    ),
                    _vm.value.address_street &&
                    _vm.value.address_city &&
                    _vm.value.address_state &&
                    _vm.value.address_zip &&
                    _vm.value.address_country &&
                    _vm.value.address_name
                      ? _c("span", { staticClass: "col-span-10 py-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.value.address_street +
                                  ", " +
                                  _vm.value.address_city +
                                  ", " +
                                  _vm.value.address_state +
                                  " " +
                                  _vm.value.address_zip +
                                  ", " +
                                  _vm.textOfEnum(
                                    _vm.allCountries,
                                    _vm.baseConsumer.address_country
                                  ) +
                                  " " +
                                  _vm.value.address_name
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
            ]
          : _vm._e(),
        _vm.fieldType === "edit"
          ? [
              _c(
                "div",
                {
                  staticClass:
                    "col-start-1 col-span-2 py-2 text-right font-bold",
                },
                [
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(_vm.$t("commons.fields.company_details"))),
                  ]),
                ]
              ),
              _c("div", { staticClass: "profile-input-field col-span-5" }),
              _vm.fieldType == "edit"
                ? [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.main_image")))]
                    ),
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "col-span-4" },
                          [
                            _c("FlDnD", {
                              attrs: {
                                extensions: ["jpg", "png"],
                                maxFileSize: 40 * 1024 * 1024,
                              },
                              on: { input: _vm.mainFileDropped },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "col-span-6" }, [
                      _vm.mainImageSrc !== null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "movie-image-container w-full relative",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "w-full object-cover object-center absolute top-0 left-0 h-full",
                                attrs: { src: _vm.mainImageSrc },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
              _vm.fieldType == "edit"
                ? [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [_vm._v(_vm._s(_vm.$t("commons.fields.logo")))]
                    ),
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "col-span-4" },
                          [
                            _c("FlDnD", {
                              attrs: {
                                extensions: ["jpg", "png"],
                                maxFileSize: 40 * 1024 * 1024,
                              },
                              on: { input: _vm.logoFileDropped },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "col-span-6" }, [
                      _vm.logoImageSrc !== null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "movie-image-container w-full relative",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "w-full object-cover object-center absolute top-0 left-0 h-full",
                                attrs: { src: _vm.logoImageSrc },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
              [
                _c(
                  "label",
                  { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                  [_vm._v(_vm._s(_vm.$t("commons.fields.payment_period")))]
                ),
                _vm.isEdit
                  ? [
                      _c(
                        "div",
                        { staticClass: "inline-block col-span-4" },
                        [
                          _c("fl-select", {
                            attrs: {
                              required: false,
                              options: _vm.paymentPeriod,
                              fieldId: "payment_period",
                            },
                            model: {
                              value: _vm.value.payment_period,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "payment_period", $$v)
                              },
                              expression: "value.payment_period",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-span-4" },
                        [
                          _c("fl-labeled-input", {
                            attrs: {
                              rules: { min: 1, max: 12, numeric: true },
                              fieldId: "payment_start_month",
                            },
                            model: {
                              value: _vm.value.payment_start_month,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "payment_start_month", $$v)
                              },
                              expression: "value.payment_start_month",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "col-span-1 h-10 py-2" },
                        [
                          _c("fl-show", {
                            attrs: {
                              value: _vm.baseConsumer.payment_period,
                              options: _vm.paymentPeriod,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-span-3 h-10 py-2" },
                        [
                          _c("fl-show", {
                            attrs: { value: _vm.paymentStartMonth },
                          }),
                        ],
                        1
                      ),
                    ],
              ],
              _vm.isEdit
                ? [
                    _c(
                      "div",
                      { staticClass: "col-span-12" },
                      [
                        _c("fl-translatable-textarea", {
                          attrs: {
                            fieldId: "about",
                            rules: { required: _vm.isRequired, max: 5000 },
                            rules2: { required: _vm.isRequired, max: 5000 },
                            label: _vm.$t("pages.consumers.profile.about_us"),
                            fromLanguage: "en",
                            toLanguage: "ja",
                          },
                          model: {
                            value: _vm.value.translations,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "translations", $$v)
                            },
                            expression: "value.translations",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-12" },
                      [
                        _c("fl-translatable-textarea", {
                          attrs: {
                            fieldId: "pr",
                            rules: { required: _vm.isRequired, max: 5000 },
                            rules2: { required: _vm.isRequired, max: 5000 },
                            label: _vm.$t("pages.consumers.profile.company_pr"),
                            fromLanguage: "en",
                            toLanguage: "ja",
                          },
                          model: {
                            value: _vm.value.translations,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "translations", $$v)
                            },
                            expression: "value.translations",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("pages.consumers.profile.about_us"))
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "col-span-10 py-2" }, [
                      _vm._v(
                        _vm._s(_vm.value.translations[_vm.$i18n.locale].about)
                      ),
                    ]),
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 py-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("pages.consumers.profile.company_pr"))
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "col-span-10 py-2" }, [
                      _vm._v(
                        _vm._s(_vm.value.translations[_vm.$i18n.locale].pr)
                      ),
                    ]),
                  ],
              _c(
                "label",
                { staticClass: "col-start-1 col-span-2 text-right" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.consumers.profile.delivery_information")
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.value.delivery_files.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-span-10 rounded border border-gray-400 overflow-hidden",
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass:
                            "col-span-10 min-w-full divide-y divide-gray-200",
                        },
                        [
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                _vm.value.delivery_files,
                                function (item, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index,
                                      class:
                                        index % 2 === 0
                                          ? "bg-gray-50"
                                          : "bg-gray-100",
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.file_name) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-6 py-4 whitespace-nowrap",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "px-2 py-2 bg-base-yellow capitalize mr-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deliveryFileDownload(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "commons.fields.download"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-6 py-4 whitespace-nowrap",
                                        },
                                        [
                                          _vm.isEdit
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "px-2 py-2 bg-base-yellow capitalize mr-2",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deliveryFileDelete(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "commons.fields.delete"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm.isEdit
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { attrs: { colspan: "3" } },
                                      [
                                        _c("FlDnD", {
                                          attrs: {
                                            extensions: ["pdf"],
                                            maxFileSize: 40 * 1024 * 1024,
                                            outlineClass: "",
                                          },
                                          on: {
                                            input: _vm.deliveryFileDropped,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.loginUserRole === "manager"
                ? [
                    _c(
                      "label",
                      { staticClass: "col-start-1 col-span-2 text-right" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pages.consumers.profile.admin_comment")
                          )
                        ),
                      ]
                    ),
                    _vm.isEdit
                      ? _c("div", { staticClass: "col-span-10" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.value.admin_comment,
                                expression: "value.admin_comment",
                              },
                            ],
                            staticClass: "w-full input_text h-40",
                            domProps: { value: _vm.value.admin_comment },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.value,
                                  "admin_comment",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _c("div", { staticClass: "col-span-10 py-2" }, [
                          _c("span", { staticClass: "whitespace-pre" }, [
                            _vm._v(_vm._s(_vm.value.admin_comment)),
                          ]),
                        ]),
                  ]
                : _vm._e(),
              _vm.quoteSets.length > 0
                ? _vm._l(_vm.quoteSets, function (quoteSet, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "col-span-12 grid grid-cols-12 gap-6",
                      },
                      [
                        index === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-start-1 col-span-2 mb-4 text-right font-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.consumers.profile.saved_conditions"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "col-start-4 col-span-6 flex items-center justify-between",
                          },
                          [
                            _c(
                              "li",
                              { staticClass: "text-left break-words w-56" },
                              [_vm._v(_vm._s(quoteSet.set_name))]
                            ),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-2 py-1 bg-light-yellow font-bold h-10 capitalize mr-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectQuoteView(quoteSet)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("pages.consumers.cart.check")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-2 py-1 bg-brown-grey font-bold h-10 capitalize",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteQuoteSet(quoteSet)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("pages.consumers.cart.delete")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  })
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }